import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

const Home = lazy(() => import('./Home'));
const Users = lazy(() => import('./users/Users'));
const NewUser = lazy(() => import('./users/NewUser'));
const EditUser = lazy(() => import('./users/EditUser'));
const Account = lazy(() => import('./auth/Account'));
const Transactions = lazy(() => import('./abn/Transactions'));
const MSPTransactions = lazy(() => import('./transactions/MSPTransactions'));
const SavedTransactions = lazy(() => import('./transactions/SavedTransactions'));
const PurchaseEntries = lazy(() => import('./transactions/PurchaseEntries'));
const EntryReports = lazy(() => import('./transactions/EntryReports'));
const PartnerTransactions = lazy(() => import('./transactions/PartnerTransactions'));
const CustomerStats = lazy(() => import('./transactions/CustomerStats'));
const FilterPartnerExport = lazy(() => import('./transactions/FilterPartnerExport'));
const ArchiveTransactions = lazy(() => import('./transactions/ArchiveTransactions'));
const ImportTransactionQuantities = lazy(() => import('./transactions/ImportTransactionQuantities'));
const AggregatedTransactionReports = lazy(() => import('./transactions/AggregatedTransactionReports'));
const CreateAggregatedTransactionReports = lazy(() => import('./transactions/CreateAggregatedTransactionReports'));
const DeviceTypes = lazy(() => import('./pin/DeviceTypes'));
const PartnerCodes = lazy(() => import('./pin/PartnerCodes'));
const Devices = lazy(() => import('./pin/Devices'));
const NewDevice = lazy(() => import('./pin/NewDevice'));
const ViewDevice = lazy(() => import('./pin/ViewDevice'));
const EditDevice = lazy(() => import('./pin/EditDevice'));
const DeviceAvailability = lazy(() => import('./pin/DeviceAvailability'));
const LinkedDevices = lazy(() => import('./pin/LinkedDevices'));
const LinkDevice = lazy(() => import('./pin/LinkDevice'));
const ViewLinkedDevice = lazy(() => import('./pin/ViewLinkedDevice'));
const EditLinkedDevice = lazy(() => import('./pin/EditLinkedDevice'));
const ExactOAuth = lazy(() => import('./auth/ExactOAuth'));
const ExactOAuthRedirect = lazy(() => import('./auth/ExactOAuthRedirect'));
const Tasks = lazy(() => import('./tasks/Tasks'));
const InvoiceCards = lazy(() => import('./invoices/InvoiceCards'));
const CreateInvoices = lazy(() => import('./invoices/CreateInvoices'));
const ViewInvoiceCard = lazy(() => import('./invoices/ViewInvoiceCard'));
const CardTypes = lazy(() => import('./commissions/CardTypes'));
const NewCardType = lazy(() => import('./commissions/NewCardType'));
const EditCardType = lazy(() => import('./commissions/EditCardType'));
const ViewCardType = lazy(() => import('./commissions/ViewCardType'));
const Commissions = lazy(() => import('./commissions/Commissions'));
const NewCommission = lazy(() => import('./commissions/NewCommission'));
const EditCommission = lazy(() => import('./commissions/EditCommission'));
const ViewCommission = lazy(() => import('./commissions/ViewCommission'));
const CommissionFees = lazy(() => import('./commissions/CommissionFees'));
const Configure = lazy(() => import('./configure/Configure'));
const Calls = lazy(() => import('./exact/Calls'));
const ExportCustomers = lazy(() => import('./exact/ExportCustomers'));
const Countries = lazy(() => import('./countries/Countries'));
const NewCountry = lazy(() => import('./countries/NewCountry'));
const EditCountry = lazy(() => import('./countries/EditCountry'));
const ViewCountry = lazy(() => import('./countries/ViewCountry'));

export default function RoutesContainer({ user, country }) {
	const admin = user.roles.includes('admin');
	return <Routes>
		<Route path="/tasks" element={<Tasks user={user} />} />
		<Route path="/devices/link/:id" element={<LinkDevice />} />
		<Route path="/devices/links/edit/:id" element={<EditLinkedDevice />} />
		<Route path="/devices/links/view/:id" element={<ViewLinkedDevice />} />
		<Route path="/devices/links" element={<LinkedDevices />} />
		<Route path="/devices/edit/:id" element={<EditDevice />} />
		<Route path="/devices/view/:id" element={<ViewDevice />} />
		<Route path="/devices/new" element={<NewDevice />} />
		<Route path="/devices/availability" element={<DeviceAvailability />} />
		<Route path="/devices" element={<Devices />} />
		<Route path="/deviceTypes" element={<DeviceTypes />} />
		<Route path="/partnerCodes" element={<PartnerCodes />} />
		{admin && <Route path="/users/edit/:id" element={<EditUser />} />}
		{admin && <Route path="/users/new" element={<NewUser />} />}
		{admin && <Route path="/users" element={<Users />} />}
		<Route path="/account" element={<Account user={user} />} />
		<Route path="/purchaseentries/reports" element={<EntryReports />} />
		<Route path="/purchaseentries" element={<PurchaseEntries />} />
		<Route path="/transactions/archive" element={<ArchiveTransactions />} />
		<Route path="/transactions/customerstats" element={<CustomerStats />} />
		<Route path="/transactions/filterexport" element={<FilterPartnerExport />} />
		<Route path="/transactions/partners" element={<PartnerTransactions />} />
		<Route path="/transactions/quantityimport" element={<ImportTransactionQuantities />} />
		<Route path="/transactions/reports/create" element={<CreateAggregatedTransactionReports />} />
		<Route path="/transactions/reports" element={<AggregatedTransactionReports />} />
		<Route path="/transactions/msp" element={<MSPTransactions />} />
		<Route path="/transactions/:state" element={<SavedTransactions user={user} country={country} />} />
		<Route path="/transactions" element={<Transactions />} />
		<Route path="/cardTypes/edit/:id" element={<EditCardType />} />
		<Route path="/cardTypes/view/:id" element={<ViewCardType />} />
		<Route path="/cardTypes/new" element={<NewCardType />} />
		<Route path="/cardTypes" element={<CardTypes />} />
		<Route path="/commissions/fees" element={<CommissionFees />} />
		<Route path="/commissions/edit/:id" element={<EditCommission />} />
		<Route path="/commissions/view/:id" element={<ViewCommission />} />
		<Route path="/commissions/new" element={<NewCommission />} />
		<Route path="/commissions" element={<Commissions />} />
		<Route path="/invoices/view/:id" element={<ViewInvoiceCard />} />
		<Route path="/invoices/create" element={<CreateInvoices />} />
		<Route path="/invoices/:state" element={<InvoiceCards user={user} />} />
		<Route path="/oauth/exact/redirect" element={<ExactOAuthRedirect />} />
		<Route path="/oauth/exact" element={<ExactOAuth />} />
		<Route path="/configure" element={<Configure />} />
		<Route path="/calls" element={<Calls />} />
		<Route path="/customerExport" element={<ExportCustomers />} />
		<Route path="/countries/edit/:id" element={<EditCountry />} />
		<Route path="/countries/view/:id" element={<ViewCountry />} />
		<Route path="/countries/new" element={<NewCountry />} />
		<Route path="/countries" element={<Countries />} />
		<Route path="/" element={<Home user={user} country={country} />} />
	</Routes>;
}
